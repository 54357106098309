body {
  background-color: white;
  font-family: Arial;
}

.draw-wrapper {
  display: grid;
  grid-template-rows: 120px 1fr;
  border: 2px solid red;
  width: 1810px;
  margin: auto;
  font-family: fantasy;

  .tourname-info {
    padding: 20px 40px 0;
    display: grid;
    grid-template-columns: 1fr 310px;

    .tourname-level-wrapper {
      .tourname-name {
        font-size: 40px;
        font-weight: 900;
        color: red;
        font-family: system-ui;
      }

      .tourname-level {
        font-size: 30px;
        font-weight: 900;
        color: black;
      }
    }

    .tourname-logo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      padding-bottom: 15px;

      .bavija-logo,
      .favija-logo,
      .lotus-logo {
        height: 90px;
        width: 90px;
      }
    }
  }

  .draw {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    color: white;
    padding: 5px;

    .left,
    .center,
    .center-two,
    .right {
      display: grid;

      .pair-wrapper {
        display: grid;
        grid-template-columns: 60px 1fr;
      }
      
      .pair-index {
        background-color: black;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        padding-top: 14px;
        border-bottom: 2px solid white;
      }
      
      .pair-info {
        display: grid;
        grid-template-rows: 1fr 1.2fr 2px;
        background-color: red;
      }
      
      .pair-border {
        width: 80px;
        background-color: white;
      }
      
      .pair-name {
        padding: 10px 15px 0;
        font-size: 18px;
        font-weight: bold;
      }
      
      .pair-club {
        padding: 6px 15px 0;
        font-size: 20px;
      }
    }
  }
}

.tournament-draw {
  width: 1600px;
  margin: auto;
  .print-draw-wrapper {
    display: grid;
    grid-template-rows: 40px 1fr;

    .level-name {
      font-weight: bold;
      font-size: 20px;
    }

    .draw {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .draw-detail {
        height: 300px;
        border: 1px solid gray;
        img {
          width: 400px;
          height: 90px;
        }
        .tournament-1 img {
          border-bottom: 2px solid #ffebd3;
        }
        .tournament-2 img {
          border-bottom: 2px solid #b8e7fb;
        }
        .pair-wrapper {
          height: 207px;
          display: flex;
          justify-content: center;
          align-items: center; 
          position: relative;
          .pair-info {
            .pair-name {
              font-size: 32px;
              text-align: center;
              font-weight: bold;
            }
            .pair-club {
              font-size: 32px;
              font-weight: bold;
              text-align: center;
            }
          }
          .level-sign {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .tournament-draw {
    width: 1200px;
    .print-draw-wrapper {
      .draw {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tournament-draw {
    width: 800px;
    .print-draw-wrapper {
      .draw {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .tournament-draw {
    width: 400px;
    .print-draw-wrapper {
      .draw {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
