.PlayerBox {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    .Header {
        display: flex;
    }
    .CardBody {
        padding: 0;
        .Body {
            display: flex;
            width: 100%;
            @media screen and (max-width:480px){
                flex-direction: column;
            }
            
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem;
            .serve {
                width: 2rem;
            }
            .name {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
            .gender {
                width: 3rem;
                @media screen and (max-width:480px){
                    font-size: small;
                }
                font-size: xx-large;
            }
        }
    }
}
.Card {
    width: 100%;
}