@import "../../common/styles.scss";

.court-manage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;

  .court {
    flex: 0 0 calc(25% - 20px);
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

    h2 {
      margin-bottom: 20px;
      color: #343a40;
    }

    label {
      display: block;
      margin-bottom: 10px;

      input {
        margin-left: 10px;
      }
    }

    button {
      margin-top: 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
