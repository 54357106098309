.mix-one-result-wrapper {
  margin-top: 40px !important;
  width: 1922px;
  height: 1080px;
  display: grid;
  grid-template-rows: 243px 1fr 130px;
  margin: auto;
  border: 1px solid gray;

  .header-wrapper {
    img {
      width: 1920px;
      height: 243px;
    }
  }

  .footer-wrapper {
    img {
      width: 1920px;
      height: 130px;
      opacity: 0.9;
    }
  }

  .body-wrapper {
    background-color: #cc2b2b;
    display: grid;
    grid-template-rows: 100px 1fr;
    row-gap: 50px;

    .draw-title {
      color: white;
      font-size: 50px;
      font-weight: bold;
      width: 1000px;
      margin: auto;
      font-family: fantasy;
      padding-top: 50px;
      text-align: center;
    }

    .result-wrapper {
      display: grid;
      grid-template-columns: 400px 400px;
      width: 1000px;
      margin: auto;
      margin-top: 0;
      column-gap: 200px;
      height: 500px;

      .group-wrapper {
        background-color: white;
        width: 400px;
        border-radius: 15px;
        -webkit-box-shadow: 0 0 10px #fff;
        box-shadow: 0 0 10px #fff;
        padding: 20px 25px;

        .group-name {
          font-size: 30px;
          color: #717572;
          font-weight: bold;
          border-bottom: 2px solid #e6e6e6;
          text-align: center;
          padding-bottom: 5px;
        }

        .pair-wrapper {
          padding: 30px 10px;
          display: grid;
          row-gap: 35px;

          .pair-detail {
            display: grid;
            grid-template-columns: 70px 1fr;
            column-gap: 20px;

            .team-name {
              border-right: 2px solid #e6e6e6;

              img {
                width: 60px;
                height: 60px;
              }
            }

            .player-wrapper {

              .player-name {
                font-size: 20px;
                font-weight: bold;
                color: #636262;
              }
            }
          }
        }
      }
    }
  }
}