@import "../../common/styles.scss";

.DashboardPage {
    .Btn {
        margin: 8px;
    }
    .Container {
        margin-top: 30px;
    }
    @media screen and (max-width : 600px) { 
        .Container {
            margin-top: 15px;
        }
    }
    .CardText {
        @extend .header-01;
    }
    .CardBody {
        min-height: 400px;
        .ResponsiveContainer {
            background-color: red;
            height: 400px;
            width: 100%;
            .PieChart {
                background-color: red;
            }
        }    
    }
}
.img {
    width: 100%;
    object-fit: contain;
}