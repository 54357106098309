@import "../../common/styles.scss";

.HomePage {
    display: flex;
    flex-direction: column;
    background-color: $gray-200;
    min-height: calc(100vh - 56px);
    .Body {
        .Sidebard {
            @media screen and (max-width:480px){
                
            }
            @media screen and (min-width:800px){
                min-height: calc(100vh - 56px);
            }
            background-color: $gray-300;
            .SidebardItem {
                padding: 1rem 0 0 0;
            }
        }    
    }
}
.Card {
    width: 100%;
}