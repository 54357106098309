.StrategyBoard {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    .Header {
        display: flex;
    }
    .Score {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        .serve {
            width: 2rem;
        }
        .name {
            display: flex;
            flex-direction: column;
            padding-right: 1rem;
            flex: 1;
        }
        .score {
            width: 3rem;
            font-size: xx-large;
        }
    }

}
.Card {
    width: 100%;
}