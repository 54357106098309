@import "../../common/styles.scss";

.PlayerPage {
  width: 100%;

  .TournamentPage {
    .LevelTitle {
      font-size: 28px;
      font-weight: bold;
    }

    .LevelPage {
      margin-bottom: 20px;
      display: grid;
      column-gap: 10px;
      row-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1000px) {
      .LevelPage {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media only screen and (max-width: 770px) {
      .LevelPage {
        grid-template-columns: 1fr;
      }
    }
  }

  .TournamentDetail {
    .TournamentImage {
      img {
        width: 100%;
      }
    }

    .TournamentDesc {
      text-align: center;
    }
  }
}

.ModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  font-size: 20px;
  font-weight: bold;
}

.ModalBody {
  padding: 10px 20px;
  .PlayerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .InfoLabel {
      font-size: 20px;
    }
    .InfoValue {
      font-size: 20px;
      width: 50%;
    }
  }
}

.ModalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ccc;

  .CloseButton {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      background-color: #ccc;
    }
  }

  .SaveButton {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #ccc;
    }
  }
}
