@import "../../common/styles.scss";

.LandingPage {
    padding-top: 1rem;
    .Btn {
        margin: 8px;
    }
}
.img {
    width: 100%;
    object-fit: contain;
}