@import "../../common/styles.scss";

.EventsPage {
    width: 100%;
    .CardDeck {
        flex: 0 0 auto;
        .Card {
            @media screen and (max-width:480px){
                min-width: calc(100%);
            }
            @media screen and (max-width:800px) and (min-width:481px){
                min-width: 40vw;
                max-width: 40vw;    
            }
            min-width: 25vw;
            max-width: 25vw;
            margin-bottom: 30px;
        }
    }
}