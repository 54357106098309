@import "../../common/styles.scss";

.RandomTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    .Button {
        margin: 0.5rem;
    }
    .Control {
        :global(.dropdown-menu){
            height: 50vh;
            overflow: auto;
        }
    }
    .Accordion {
        width: 100%;
        .Button {
            margin-right: 1rem;
        }
        .ButtonGroup {
            margin-bottom: 1rem;
        }
        .dropBody {
            overflow: auto;
            padding: 0.5rem;
        }
    }
}
.Card {
    width: 100%;
}