.incoming-matches-page {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 1920px;
  height: 1080px;
  border: 1px solid black;

  .court {
    padding: 10px 20px;
    border: 5px solid red;
    box-sizing: border-box;

    .court-name {
      font-size: 40px;
      font-weight: bold;
      color: white;
      font-family: fantasy;
      padding: 5px;
      text-align: center;
      background-color: red;
    }

    .match {
      margin-top: 20px;

      h2 {
        font-weight: bold;
        font-size: 40px;
      }

      .score-wrapper {
        padding: 0 20%;
        display: flex;
        justify-content: space-between;
        
        text-align: center;

        .score, .score-vs-label {
          font-size: 120px;
          font-weight: bold;
        }

        .player {
          font-size: 22px;
          font-weight: bold;
        }

      }
    }

    .current {
      font-weight: bold;
    }
    
    .next {
      color: gray;
      font-size: 30px;
    }
  }
}