@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

.header-01 {
    font-weight: bold;
    font-size: large;
}
.Breadcrumb {
    :global(.breadcrumb){
        margin: 0;
    }
}

$primary: #007bff;