.Scoreboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    .Button {
        margin: 0.5rem;
    }
}
.Card {
    width: 100%;
}