.duel-participant {
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;

  .home-area {
    max-width: 280px;
    margin-left: auto;
  }

  .away-area {
    max-width: 280px;
  }

  .participant-doubles-wrapper {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    width: 280px;

    .participant-doubles {
      .participant-link {
        align-items: center;
        background: #fff;
        border: 1px solid #001e28;
        border-radius: 12px;
        display: flex;
        grid-area: image;
        justify-content: center;
        overflow: hidden;
        padding: 4px;
      }

      .participant-name {
        text-align: center;
      }

      img {
        width: 125px;
        height: 125px;
      }
    }

    .score-control {
      text-align: center;

      .minus-button {
        width: 60px;
        border: none;
        background-color: orange;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
      }

      .plus-button {
        width: 60px;
        border: none;
        background-color: #3287a8;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
      }
    }
  }

  .score {
    .start-time {
      text-align: center;
    }

    .match-info {
      .detail-score-wrapper {
        font-weight: bold;
        font-size: 70px;
        text-align: center;
        font-family: FS_Numbers;

        .match-info-item {
          display: grid;
          column-gap: 10px;
          grid-template-columns: 1fr 0.5fr 1fr;
        }

        .home-score {
          text-align: right;
        }

        .away-score {
          text-align: left;
        }

        .detail-score-divider {
          margin-bottom: 5px;
        }
      }

      .detail-score-status {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}


@media only screen and (max-width: 992px) {
  .duel-participant {
    display: block;
  }

  .home-area {
    max-width: 280px;
    margin: 20px auto;
  }

  .away-area {
    max-width: 280px;
    margin: 20px auto;
  }
}