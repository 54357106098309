@import "../../common/styles.scss";

.PLayerAvatar {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 7fr;
  margin-bottom: 10px;

  .Avatar {
    width: 100px;
    height: 100px;
  }

  .PLayerName {
    padding-top: 50px;
  }

  .PLayerNameWrapper {
    display: flex;

    .EditButton {
      margin-top:55px;
      margin-left: 10px;
      height: 35px;
      width: 70px;
      border-radius: 5px;
    }

    .SaveButton {
      background-color: green;
      color: white;
      border: 0px;
      font-weight: bold;
    }

    .CancelButton {
      background-color: red;
      color: white;
      border: 0px;
      font-weight: bold;
    }
  }
}
