.match-page {
  padding: 20px;
  .match-page-livestream {
    .game-1 {
      .score-board {
        width: 273px;
        grid-template-columns: 230px 40px;
      }
    }

    .game-2 {
      .score-board {
        width: 316px;
        grid-template-columns: 230px 40px 40px;
      }
    }

    .game-3 {
      .score-board {
        width: 362px;
        grid-template-columns: 230px 40px 40px 40px;
      }
    }

    .game-1,
    .game-2,
    .game-3 {
      margin-bottom: 10px;

      .score-board {
        background-color: #c93a3a;
        height: 66px;
        display: grid;
        column-gap: 3px;
        row-gap: 3px;
        border: 1px solid #d1d6de;
      
        .upper-player-wrapper {
          padding-left: 3px;
          padding-top: 0px;
          font-weight: bold;
          background-color: white;
          display: grid;
          grid-template-columns: 22px 6.5fr 0.5fr;
          column-gap: 10px;

          &.team-wrapper {
            grid-template-columns: 30px 6.5fr 0.5fr;
          }
        }

        .player-name {
          padding-top: 3px;
        }
      
        .upper-score-previous-game {
          background-color: white
        }
        
        .upper-score-current-game {
          color: white;
        }
      
        .lower-player-wrapper {
          padding-left: 3px;
          padding-top: 0;
          padding-right: 3px;
          font-weight: bold;
          background-color: white;
          display: grid;
          grid-template-columns: 22px 6.5fr 0.5fr;
          column-gap: 10px;

          &.team-wrapper {
            grid-template-columns: 30px 6.5fr 0.5fr;
          }
        }
      
        .lower-score-previous-game {
          background-color: white;
        }
        
        .lower-score-current-game {
          color: white;
        }
      
        .center {
          padding-top:4px;
          text-align: center;
          font-weight: bold;
        }
      
        .country-flag {
          width: 22px;
          img {
            height: 22px;
            width: 22px;
            border: 1px solid #d1d6de;
            border-radius: 50%;
          }
        }

        .team-flag {
          width: 30px;
          img {
            height: 30px;
            width: 30px;
          }
        }
        
        .shuttle-cock {
          color: #c93a3a;

          &.hidden {
            display: none;
          }
        }
      }

    }
  }

  .scoreboard-controller {
    margin-top: 40px;
    border: 5px solid red;
    padding: 10px 10px;

    .match-name {
      max-width: 300px;
      margin: 10px auto;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      background-color: red;
      padding: 10px 0;
      color: white;
    }

    .game-1 {
      margin-bottom: 50px;
      padding: 10px;
      border: 4px solid red;

      .game-1-title {
        max-width: 160px;
        margin: 0 auto;
        font-size: 30px;
        color: red;
        font-weight: bold;
        border: 2px solid red;
        padding: 5px;
        text-align: center;
      }
    }

    .game-2 {
      margin-bottom: 50px;
      padding: 10px;
      border: 4px solid blue;

      .game-2-title {
        max-width: 160px;
        margin: 0 auto;
        font-size: 30px;
        color: blue;
        font-weight: bold;
        border: 2px solid blue;
        padding: 5px;
        text-align: center;
      }
    }

    .game-3 {
      margin-bottom: 50px;
      padding: 10px;
      border: 4px solid green;

      .game-3-title {
        max-width: 160px;
        margin: 0 auto;
        font-size: 30px;
        color: green;
        font-weight: bold;
        border: 2px solid green;
        padding: 5px;
        text-align: center;
      }
    }
  }
}

