.BadmintonBoard {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .Header {
        display: flex;
    }
    .BoardBody {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .BoardContainer {
            background-color: green;
            display: flex;
            justify-content: center;
            align-items: center;
            .Board {
                .Drag {
                    width: min-content;
                }
                position: relative;
                background-color: green;
                .Move {
                    width: min-content;
                    .Icon {
                        font-size: xxx-large;
                    }
                    .IconRed {
                        @extend .Icon;
                        color: rgb(152, 34, 34);
                    }   
                    .IconBlue {
                        @extend .Icon;
                        color: rgb(17, 17, 121);
                    } 
                    .IconPink {
                        @extend .Icon;
                        color: rgb(138, 15, 138);
                    } 
                }
            }    
        }
        .serve {
            width: 2rem;
        }
        .name {
            display: flex;
            flex-direction: column;
            padding-right: 1rem;
            flex: 1;
        }
        .score {
            width: 3rem;
            font-size: xx-large;
        }
    }

}
.Card {
    width: 100%;
}